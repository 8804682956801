import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextMediaSplit = makeShortcode("TextMediaSplit");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextMediaSplit imagePath="portfolio/golden-snub-nosed/golden-snub-nosed-main.jpg" imageAlt="Golden Snub-Nosed Monkey" mdxType="TextMediaSplit">
      <h1>{`Golden Snub-Nosed Monkey`}</h1>
      <p>{`Digital artwork of the beautiful  Golden Snub-Nosed monkey. If you know them from BBC’s Seven Worlds One Planet you may be thinking awww, if you know them from HBO’s His Dark Materials you may be getting a chill down your spine!`}</p>
      <p>{`Made in Sketchbook.`}</p>
    </TextMediaSplit>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      